import React from 'react'
import FourZeroFourView from 'views/404-view'
import JobsView from 'views/jobs-view'
import { fetchAllJobRequisitions, fetchJobFilters } from 'utils/sanity/job-requisitions'

const JobsPage = props => {
  const {
    serverData: { error, jobFilters, jobRequisitions },
  } = props

  if (error) {
    return <FourZeroFourView />
  }

  return <JobsView jobFilters={jobFilters} jobRequisitions={jobRequisitions} />
}

export const getServerData = async () => {
  try {
    const [jobRequisitions, jobFilters] = await Promise.all([
      fetchAllJobRequisitions(),
      fetchJobFilters(),
    ])

    return {
      props: {
        jobFilters,
        jobRequisitions,
      },
    }
  } catch (error) {
    console.error(error)
    return {
      props: {
        error: true,
      },
    }
  }
}

export default JobsPage
